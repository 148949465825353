/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import parse from 'html-react-parser'
import TitleDefault from 'components/elements/TitleDefault'

// Components
import CustomLink from 'components/shared/CustomLink'

// Elements
import { HeroCSS, HeroImageCSS, HeroGradientCSS } from 'components/elements/HeroShared'

const StyledParty = styled.div`
  ${HeroCSS};
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: center;
`

const Image = styled.div`
  ${HeroImageCSS};
`

const Gradient = styled.div`
  ${HeroGradientCSS};
  background: transparent linear-gradient(180deg, #101010 0%, #3434342B 100%, #3C3C3C00 100%) 0% 0% no-repeat padding-box;
`

const Title = styled(TitleDefault)`
  color: ${props => props.theme.color.text.contrast};
  font-weight: ${props => props.theme.font.weight.l};
  font-size: ${props => props.theme.font.size.xxl};
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  margin-bottom: 35px;
`

const Options = styled.div`
  position: relative;
  z-index: 2;
`

const Option = styled(CustomLink)`
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.ml};
  text-transform: uppercase;
  display: flex;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.color.text.contrast};
  }
`

const Party = ({ image, title, options, className }) => (
  <StyledParty className={className}>
    <Image>
      <Gradient />
      <BackgroundImage fluid={image.localFile.childImageSharp.fluid} />
    </Image>
    <div className="container d-flex h-100">
      <div className="row h-100 w-100">
        <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center mb-lg-4 py-3">
          <Title>
            {parse(title)}
          </Title>
          <Options>            
            {options.map(( { node: { wpId, title: subTitle, link } }) => (
              <div className="col-md-6 col-lg-4 d-inline-flex justify-content-center" key={wpId}>
                <Option to={link}>
                  <span>{subTitle}</span>
                </Option>
              </div>
            ))}
          </Options>
        </div>
      </div>
    </div>
  </StyledParty>
)

export default Party