/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import CallToAction from 'components/CallToAction'
import MoreInformation from 'components/MoreInformation'
import Party from 'components/Party'
import Halls from 'components/Halls'
import ArrangementPrices from 'components/ArrangementPrices'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ContentDefault from 'components/elements/ContentDefault'
import MoodImage from 'components/elements/MoodImage'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      path
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        partyTitle: party_title
        partyImage: party_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        arrangement: arrangement_overview
        contactRelation: contact_relation
        ctaRelation: cta_relation
        moodImage: view_mood_image {  
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
    options: allWordpressPage(
      filter: { parent_element: { wordpress_id: { eq: $wordpress_id } } }
    ) {
      edges {
        node {
          wpId: wordpress_id
          title
          link
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      title,
      path,
      content,
      yoastMeta,
      acf,
    },
    options,
  },
}) => {
  return (
    <Layout headerTop>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.backgroundImage.localFile.childImageSharp.meta.src}
        article
      />
      <HeroDefault title={title} image={acf.backgroundImage} />
      <div className="">
        <section>
          <div className="container ">
            <div className="row">
              <div className="col-lg-7 py-5">
                <TitleSub>{parse(title)}</TitleSub>
                <BrandTitle />
                <ContentDefault className="mt-4" content={content} />
              </div>
              <div className="col-lg-1" />
              <div className="col-lg-4">
                <CallToAction id={acf.ctaRelation} />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="py-5">
              <ArrangementPrices className="mb-lg-5 pb-5" ids={acf.arrangement} />
            </div>
          </div>
        </section>
        {options.edges.length && options.edges.length > 0 ? (
          <section className="mt-5 mt-lg-0">
            <Party image={acf.partyImage} title={acf.partyTitle} options={options.edges} />
          </section>
        ) : null}
      </div>
      <section>
        <MoreInformation id={acf.contactRelation} className="mt-1" />
      </section>
      <div className="container">
        <Halls className="my-5" />
      </div>

      {acf.moodImage && (
        <MoodImage image={acf.moodImage} />
      )}
    </Layout>
  )
}

export default PageTemplate
