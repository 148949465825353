/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import parse from 'html-react-parser'
import TitleDefault from 'components/elements/TitleDefault'
import { HeroCSS, HeroImageCSS, HeroGradientCSS } from './HeroShared'

const StyledHeroDefault = styled.div`
  ${HeroCSS};
  position: relative;
  height: 250px;
`

const Image = styled.div`
  ${HeroImageCSS};
`

const Gradient = styled.div`
  ${HeroGradientCSS};
`

const Title = styled(TitleDefault)`
  position: relative;
  z-index: 2;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xxl};
    text-align: center;
  }
`

const HeroDefault = ({ image, title, className }) => (
  <StyledHeroDefault className={className}>
    <Image>
      <Gradient />
      <BackgroundImage fluid={image.localFile.childImageSharp.fluid} />
    </Image>
    <div className="container d-flex justify-content-center align-items-center h-100">
      <Title>
        {parse(title)}
      </Title>
    </div>
  </StyledHeroDefault>
)

export default HeroDefault