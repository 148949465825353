/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Components
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

const StyledCallToAction = styled.div``

const Content = styled.div`
  display: flex;

  @media (min-width: 992px) {
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    justify-content: center;
  }
`

const Description = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  & strong {
    font-size: ${props => props.theme.font.size.l};
    font-family: ${props => props.theme.font.family.secondary};
    font-weight: ${props => props.theme.font.weight.l};
  }

  & p {
    margin: 0;
    color: ${props => props.theme.color.text.light};
    text-align:center;
  }
`

const Image = styled(BackgroundImage)`
  width: inherit;
  height: inherit;
  background-size: contain;
`

const Wrapper = styled.div`
  position: relative;
  width: 250px;
  height: 344px;

  @media (max-width: 991px) {
    & > ${Image} {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
    }
  }
`

const Button = styled(ButtonDefault)`
  position: absolute;
  left: -30px;
  bottom: 75px;
`

const CallToAction = ({ id, className }) => {
  const {
    allWordpressWpC2A: { edges: items },
  } = useStaticQuery(graphql`
    {
      allWordpressWpC2A {
        edges {
          node {
            wpId: wordpress_id
            acf {
              description
              phonenumber
              buttonText: button_text
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 250) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    node: {
      acf: { image, description, phonenumber, buttonText },
    },
  } = items.find(({ node: { wpId } }) => wpId === id)

  return (
    <StyledCallToAction className={className}>
      <Content>
        <div />
        <Wrapper>
          {description && (
            <Description className="mb-4 pt-3">
              <ParseContent content={description} />
            </Description>
          )}
          <Image fluid={image.localFile.childImageSharp.fluid} />
          <Button phone isAnchor to={`tel:${phonenumber}`}>
            {buttonText}
          </Button>
        </Wrapper>
      </Content>
    </StyledCallToAction>
  )
}

export default CallToAction
