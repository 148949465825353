/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { HeroCSS, HeroImageCSS, HeroGradientCSS } from './HeroShared'

const StyledMoodImage = styled.section`
  ${HeroCSS};
  position: relative;
  
  @media (min-width: 992px) {
    height: 500px;
  }

  @media (min-width: 768px) {
    height: 350px;
  }

  @media (max-width: 767px) {
    height: 250px;
  }
`

const Image = styled.div`
  ${HeroImageCSS};
`

const Gradient = styled.div`
  ${HeroGradientCSS};
`

const MoodImage = ({ className, image }) => (
  <StyledMoodImage className={className}>
    <Image>
      <Gradient />
      <BackgroundImage fluid={image.localFile.childImageSharp.fluid} />
    </Image>
  </StyledMoodImage>
)

export default MoodImage