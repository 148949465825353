/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ButtonAlt from 'components/elements/ButtonAlt'

const StyledMoreInformation = styled.section`
  position: relative;
  
  @media (min-width: 992px) {
    height: 80px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 1;
`

const BackgroundLeft = styled(Background)`
  background-color: ${props => props.theme.color.contrast};
  left: 0;
`

const BackgroundRight = styled(Background)`
  right: 0;

  @media (min-width: 992px) {
    background-color: ${props => props.theme.color.text.secondary};
  }

  @media (max-width: 991px) {
    background-color: ${props => props.theme.color.contrast};
  }
`

const Container = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`

const Content = styled.div`
  height: 100%;

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const Title = styled.h3`
  background-color: ${props => props.theme.color.contrast};
  font-size: ${props => props.theme.font.size.xl};
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.light};
  position: relative;
  letter-spacing: 1px;
  margin: 0%;
  min-width: 60%;
  height: 100%;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent ${props => props.theme.color.contrast};
    border-style: solid;
    border-width: 40px 0 40px 25px;
    left: 100%;
    top: 0;
    bottom: 0;
  }

  @media (min-width: 992px) {
    line-height: 80px;
  }

  @media (max-width: 991px) {
    &:after {
      content: none;
    }
  }
`

const MoreInformation = ({ className, id }) => {
  const {
    allWordpressWpContacts: { edges: items },
  } = useStaticQuery(graphql`
    {
      allWordpressWpContacts {
        edges {
          node {
            wpId: wordpress_id
            acf {
              title
              buttonText: button_text
              buttonLocation: button_location
            }
          }
        }
      }
    }
  `)

  const {
    node: {
      acf: { title, buttonText, buttonLocation },
    },
  } = items.find(({ node: { wpId } }) => wpId === id)

  return (
    <StyledMoreInformation className={className}>
      <BackgroundLeft />
      <BackgroundRight />
      <Container className="container py-4 py-lg-0 text-center">
        <Content>
          <Title>{title}</Title>
          <ButtonAlt to={buttonLocation} className="mt-4 mt-lg-0">
            {buttonText}
          </ButtonAlt>
        </Content>
      </Container>
    </StyledMoreInformation>
  )
}

export default MoreInformation