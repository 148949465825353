/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

// Components
import CustomLink from 'components/shared/CustomLink'

// Elements
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ContentDefault from 'components/elements/ContentDefault'

const Hexagons = styled.div`
  position: relative;
  width: 480px;
  height: 500px;

  @media (max-width: 768px) {
    width: 330px;
    height: 350px;
  }

  @media (max-width: 400px) {
    width: 100%;
    height: auto;
  }
`

const Title = styled.h3`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.l};
  font-family: ${props => props.theme.font.family.secondary};
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all .1s;
`

const Hexagon = styled(CustomLink)`
  position: absolute;

  & > .gatsby-image-wrapper {
    width: 230px;
    height: 260px;
  }

  &:hover {
    & ${Title} {
      font-size: ${props => parseFloat(props.theme.font.size.l.replace('px')) + 2}px;
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    & > .gatsby-image-wrapper {
      width: 150px;
      height: 170px;
    }
  }

  @media (max-width: 400px) {
    position: relative;
    margin: 25px auto;
    display: block;
    text-align: center;

    & > .gatsby-image-wrapper {
      width: 175px;
      height: 198px;
      margin: 0 auto;
    }
  }
`

const HexagonSecond = styled(Hexagon)`
  top: 0;
  left: 50%;
  margin-left: -115px;

  @media (max-width: 768px) {
    margin-left: -75px;
  }

  @media (max-width: 400px) {
    margin: 15px auto;
    left: 0;
  }
`

const HexagonFirst = styled(Hexagon)`
  top: 215px;
  left: 0;

  @media (max-width: 768px) {
    top: 150px;
  }

  @media (max-width: 400px) {
    top: 0;
  }
`

const HexagonThird = styled(Hexagon)`
  top: 215px;
  right: 0;

  @media (max-width: 768px) {
    top: 150px;
  }

  @media (max-width: 400px) {
    top: 0;
  }
`

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled(BackgroundImage)`
  background-size: contain;
`

const Halls = ({ className }) => {
  const {
    allWordpressPage: { edges: items },
    wordpressWpComponenten: { title, content }
  } = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 9}}}) {
        edges {
          node {
            path
            title
            wpId: wordpress_id
            acf {
              thumbnailImage: thumbnail_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 230) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
      wordpressWpComponenten(wordpress_id: {eq: 142}) {
        title
        content
      }
    }
  `)

  const { node: firstHall } = items.filter(({ node: { wpId } }) => wpId === 41)[0]
  const { node: secondHall } = items.filter(({ node: { wpId } }) => wpId === 43)[0]
  const { node: thirdHall } = items.filter(({ node: { wpId } }) => wpId === 45)[0]

  return (
    <section className={className}>
      <div className="row">
        <div className="col-lg-7 d-flex align-items-center justify-content-center">
          <Hexagons>
            <HexagonFirst to={firstHall.path}>
              <Image fluid={firstHall.acf.thumbnailImage.localFile.childImageSharp.fluid} />
              <Wrapper>
                <Title>{firstHall.title}</Title>
              </Wrapper>
            </HexagonFirst>
            <HexagonSecond to={secondHall.path}>
              <Image fluid={secondHall.acf.thumbnailImage.localFile.childImageSharp.fluid} />
              <Wrapper>
                <Title>{secondHall.title}</Title>
              </Wrapper>
            </HexagonSecond>
            <HexagonThird to={thirdHall.path}>
              <Image fluid={thirdHall.acf.thumbnailImage.localFile.childImageSharp.fluid} />
              <Wrapper>
                <Title>{thirdHall.title}</Title>
              </Wrapper>
            </HexagonThird>
          </Hexagons>
        </div>
        <div className="col-lg-5 py-4">
          <TitleSub right>
            {title}
          </TitleSub>
          <BrandTitle />
          <ContentDefault className="mt-5" content={content} />
        </div>
      </div>
    </section>
  )
}

export default Halls